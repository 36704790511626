<template>
    <div>
        <div
            v-if="bulk_status"
            class="bulk_status"
        >
            <div v-html="bulk_status" />
            <div
                class="close_icon"
                @click="closeStatuses"
            />
        </div>
        <custom-table
            :filter_settings="SeoFilters"
            :filter_items="filterItems"
            :page_filters="page_filters"
            :filters="filters"
            :headers_items="headerItems"
            :toolbar_settings="toolbarSettings"
            :links="links"
            :loading="loadingTable || !items"
            :content-type="contentType"
            :items="items"
            :options="tableOptions"
            :total="totalPrice"
            @getData="getList($event)"
            @action="updateOutreachPrice"
            @selectAllCheckbox="selectAllCheckbox($event)"
        />
    </div>
</template>

<script>
import contentBaseMixin from '@/mixins/content/contentBaseMixin'
import { headerItems } from '@/helpers/constants/seo-pages/seo-table-header-items'
import { SeoFilters } from '@/helpers/filters-settings/seo-filters'
import { FilterItems, Filters } from '@/helpers/constants/seo-pages/filters'
import { toolbarSettings } from '@/helpers/constants/seo-pages/toolbar-settings'

import { mapSeoListFields } from '@/store/modules/seo/seo-list'
import { createNamespacedHelpers } from 'vuex'

import {
    ACTION_GET_OUTREACH_LIST,
    CHANGE_PRICE_OUTREACH,
    SET_BULK_SENDING_STATUS
} from '@/store/modules/seo/seo-list/action-types'
import { debounce, isEqual } from 'lodash';

const {
    mapActions: mapSeoListActions
} = createNamespacedHelpers('seoList');

export default {
    name: 'Outreach',
    mixins: [contentBaseMixin],
    data() {
        return {
            tableOptions: {
                filters: true,
                content: true,
                toolbar: true,
                hiddenModals: true
            },
            currentRoute: 'posts-list-outreach',
            filters: {}
        }
    },
    computed: {
        ...mapSeoListFields([
            'outreach',
            'bulk_status',
            'loading'
        ]),
        headerItems() {
            return headerItems
        },
        allToolbarSettings() {
            return toolbarSettings
        },
        SeoFilters() {
            return SeoFilters
        },
        filterItems() {
            return FilterItems
        },
        items() {
            return this.outreach
        },
        loadingTable() {
            return this.loading
        },
        links() {
            return []
        },
        page_filters() {
            return Filters
        },
        totalPrice() {
            const item = this.items.data.find((item) => !!item?.sum_price)
            return `$${item?.sum_price || 0}`
        }
    },
    watch: {
        '$route.query': debounce(async function (val) {
            if (this.$route.name !== 'posts-list-outreach') return
            if (isEqual(this.$route.query, this.filters)) return
            this.filters = {
                ...val
            }
            console.log('test4')
            await this.getList(val)
        }, 50)
    },
    mounted() {
        if (Object.values(this.$route.query).length !== 0) {
            this.filters = { ...this.$route.query }
        } else {
            this.filters = { ...Filters }
        }
        this.getList(this.$route.query)
    },
    methods: {
        ...mapSeoListActions([
            ACTION_GET_OUTREACH_LIST,
            CHANGE_PRICE_OUTREACH,
            SET_BULK_SENDING_STATUS
        ]),
        getList(query = {}) {
            if (!query.from || !query.to) {
                delete query.from
                delete query.to
            }
            this[ACTION_GET_OUTREACH_LIST]({ ...query })
        },
        async updateOutreachPrice(action) {
            if (action.col !== 'price') { return }
            await this[CHANGE_PRICE_OUTREACH](action.data)
            this.getList(this.$route.query)
        },
        closeStatuses() {
            this[SET_BULK_SENDING_STATUS]('')
        }
    }
}
</script>

<style lang="scss">
    .bulk_status {
        margin-top: 7px;
        margin-left: 60px;
        padding: 28px 10px 15px 10px;
        background: #1976D2;
        color: #FFFFFF;
        margin-right: 10px;
        border-radius: 5px;
        transform: translateY(65px);
        position: relative;
        cursor: pointer;
        .close_icon {
            position: absolute;
            right: 5px;
            top: 5px;
            width: 15px;
            height: 15px;
            &::after, &::before {
                position: absolute;
                content: '';
                width: 100%;
                height: 2px;
                background: #fff;
                bottom: 50%;
                left: 0;
            }
            &::after {
                transform: rotate(45deg);
            }
            &::before {
                transform: rotate(-45deg);
            }
        }
        & > div > ul {
            margin: 10px 0;
            list-style: disc;
            padding-left: 20px;
            li {
                line-height: 20px;
            }
        }
    }
</style>
