export const SeoFilters = {
    config: {
        rowsPerPage: true,
        paginationPage: true,
        search: true
    },
    lists: {
        fieldsList: [
            {
                value: 'url_donor',
                text: 'Url donor'
            },
            {
                value: 'url_acceptor',
                text: 'Url acceptor'
            }
        ],
        statusList: [
            {
                value: 'all',
                text: 'All'
            },
            {
                value: 'live',
                text: 'Live'
            },
            {
                value: 'dead',
                text: 'Dead'
            },
            {
                value: 'unpaid',
                text: 'Unpaid'
            },
            {
                value: 'paid',
                text: 'Paid'
            }
        ]
    }
}
