export const toolbarSettings = [
    {
        name: 'ping'
    },
    {
        name: 'pay'
    },
    {
        name: 'delete'
    },
    {
        name: 'payment-type'
    }
]
