export const headerItems = [
    {
        sort_by: 'checkbox',
        styles: 'width:40px'
    },
    {
        sort_by: 'index'
    },
    {
        classes: 'custom-table__row-status',
        sort_by: 'active',
        styles: 'justify-content: center',
        title: 'Status'
    },
    {
        classes: 'custom-table__row-donor',
        styles: '',
        sort_by: 'url_donor',
        title: 'Url donor'
    },
    {
        classes: 'custom-table__row-acceptor',
        sort_by: 'url_acceptor',
        styles: 'justify-content: center',
        title: 'Acceptor'
    },
    {
        classes: 'custom-table__row-anchor',
        styles: 'justify-content: center',
        sort_by: 'anchor_text',
        title: 'Anchor'
    },
    {
        classes: 'custom-table__row-owner',
        styles: 'justify-content: center',
        sort_by: 'owner',
        title: 'Owner',
        hide_sort: true
    },
    {
        classes: 'custom-table__row-pinged',
        styles: 'justify-content: center',
        sort_by: 'pinged_at',
        title: 'Pinged'
        // hide_sort: true
    },
    {
        styles: 'justify-content: center',
        sort_by: 'type_payment',
        title: 'Type',
        hide_sort: true
    },
    {
        classes: 'custom-table__row-paid',
        styles: 'justify-content: center',
        sort_by: 'paid',
        title: 'Paid'
    },
    {
        classes: 'custom-table__row-price',
        styles: 'justify-content: center',
        sort_by: 'price',
        title: 'price'
    },
    {
        classes: 'custom-table__row-created-at',
        styles: 'justify-content: center',
        sort_by: 'created_at',
        title: 'Created'
    }
]
